import React from "react";
import { Check } from "@ui-kit/components/icon/icon.component";
import Box from "@ui-kit/components/box/box.component";
import Button from "@ui-kit/components/button/button.component";
import "./Password_Changed.scss";
import { Icon_With_Text } from "@ui-kit/components/Icon With Text/Icon_with_text.component";
import useMediaQuery from "@ui-kit/hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";

const Password_Changed = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const handleButtonClick = () => {
    navigate("/login");
    // Code to execute when the button is clicked
    console.log("Button clicked!");
  };
  return (
    <div>
      {isMobile ? (
        <div className="mobile-container-confirmation">
          {/* <p> mobile view</p> */}
          <div className="icon" style={{ background: "transparent" }}>
            <Check width={160} height={160} />
          </div>
          <p className="mobile-text">
            Your password has been successfully changed!
          </p>
          <Button
            title="Login"
            width="328px"
            height="36px"
            padding="12px, 16px, 12px, 16px"
            handler={handleButtonClick}
            color="white"
            bgColor="#228DFF"
            radius="8px"
          />
        </div>
      ) : (
        <Box width={"466px"} height={"350px"}>
          <Check width={200} height={200} />
          <p className="text">Your password has been successfully changed!</p>

          <Button
            title="Login"
            width="420px"
            // padding="10px 20px 10px 10px"
            handler={handleButtonClick}
            color="white"
            bgColor="#228DFF"
            radius="8px"
            // You can customize other props here if needed
          />
        </Box>
      )}
    </div>
  );
};

export default Password_Changed;
