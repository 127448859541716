import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://backend-a.cinqd.com/"; // Change this to your API's base URL
// const baseUrl = "http://localhost:85/"; 

export const api = createApi({
  //generated reducers will be stored in store with this path..
  //when we have multiple type of apis..
  reducerPath: "api",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (credentials) => ({
        url: "/auth/sign-up",
        method: "POST",
        body: credentials,
      }),
    }),
    // end signup
    signin: builder.mutation({
      query: (credentials) => ({
        // url: '/auth/signin-with-email',
        url: "/auth/sign-in",
        method: "POST",
        body: credentials, // Use 'data' instead of 'body'
        headers: {
          "Content-Type": "application/json", // Set the content type for JSON
          // Other headers as needed
        },
      }),
    }),
    SocialLogin: builder.mutation({
      query: (Credentials) => ({
        url: "/auth/social-login",
        method: "POST",
        body: Credentials,
      }),
    }),
    ForgotPasswordEmail: builder.mutation({
      query: (Credentials) => ({
        url: `/auth/send-email`,
        method: "POST",
        body: Credentials,
      }),
    }),
    SetNewPassword: builder.mutation({
      query: (Credentials) => ({
        url: "/auth/recover-password/",
        method: "PATCH",
        body: Credentials,
      }),
    }),
    ActivateUserAccount: builder.mutation({
      query: (email) => ({
        url: `/auth/activate-user/${email}`,
        method: "PATCH",
      }),
    }),
  }),
});

export const {
  useSignupMutation,
  useSigninMutation,
  useActivateUserAccountMutation,
  useSocialLoginMutation,
  useForgotPasswordEmailMutation,
  useSetNewPasswordMutation,
} = api;
