import React, { useState, ChangeEvent } from "react";
import styles from "./input.module.scss";
import passwordIcon from "../../assets/png/password_icon.png";
import showPasswordIcon from "../../assets/png/show-password-icon-eye-symbol.svg";
interface InputProps {
  label: string;
  type: string;
  inputName: string;
  value: string;
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  placeholder?: string;
  IsPasswordMatch?: string;
  showPasAcceptanceCrieteria?: boolean;
  apiError?: string | boolean;
  onChange: (name: string, value: string, error: string | boolean) => void;
  disabled?: boolean;
}

const Input: React.FC<InputProps> = ({
  label,
  type,
  inputName,
  value,
  required,
  minLength,
  maxLength,
  placeholder,
  IsPasswordMatch,
  showPasAcceptanceCrieteria,
  apiError,
  onChange,
  disabled,
}) => {
  const [error, setError] = useState<string | boolean>(false);
  const [isFocused, setIsFocused] = useState(false);
  const [showSuccessBorder, setShowSuccessBorder] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const validateInput = (inputValue: string) => {
    setError(false);
    setShowSuccessBorder(true);
    if (required && inputValue.trim() === "") {
      setError("This field is required.");
      setShowSuccessBorder(false);
    } else if (minLength && inputValue.length < minLength) {
      setError(`Minimum length should be ${minLength} characters.`);
      setShowSuccessBorder(false);
    } else if (maxLength && inputValue.length > maxLength) {
      setError(`Maximum length should be ${maxLength} characters.`);
      setShowSuccessBorder(false);
    } else if (
      type === "email" &&
      !/^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(inputValue)
    ) {
      setError("Invalid input format.");
      setShowSuccessBorder(false);
    } else if (type === "text" && !/^[A-Za-z ]+$/.test(inputValue)) {
      setError("Invalid input format.");
      setShowSuccessBorder(false);
    } else if (type === "password") {
      if (/\s/.test(inputValue)) {
          setError("Password cannot contain spaces.");
          setShowSuccessBorder(false);
      } else if (!/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{10,}$/.test(inputValue)) {
          setError("Enter a combination of uppercase, lowercase, and numbers.");
          setShowSuccessBorder(false);
      }
  }
  
    // } else if (
    //   type === "password" &&
    //   !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{10,}$/.test(inputValue)
    // ) {
    //   setError("Enter a combination of uppercase, lowercase, and numbers");
    //   setShowSuccessBorder(false);
    // } 
    else if (
      type === "email" &&
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputValue)
    ) {
      setError("Invalid input format.");
    } else if (type === "number" && !/^[0-9]*$/.test(inputValue)) {
      setError("Invalid input format");
    }
  };

  // const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = event.target;
  //   validateInput(value);
  //   onChange(name, value, error);
  // };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const sanitizedValue = type === "password" ? value.replace(/\s+/g, '') : value;
    validateInput(sanitizedValue);
    onChange(name, sanitizedValue, error);
};

  const handleBlur = () => {
    if (required) {
      validateInput(value);
      onChange(inputName, value, error);
    }
    setIsFocused(false);
  };
  const handleFocus = () => {
    setIsFocused(true);
  };
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };
  return (
    <div className={styles.container}>
      <p className={styles.group}>
        <div className={styles.rowContainer}>
          <input
            id={inputName}
            type={
              type === "password"
                ? passwordShown
                  ? "text"
                  : "password"
                : type == "number"
                ? "text"
                : type
            }
            name={inputName}
            placeholder={placeholder}
            value={value}
            disabled={disabled}
            style={{
              borderBottom: isFocused
                ? "1px solid #bbdcff"
                : error ||
                  (type === "password" && IsPasswordMatch === "Not Matched") ||
                  apiError
                ? "1px solid #FC5959"
                : showSuccessBorder
                ? "1px solid #63DAA0"
                : "1px solid #BDBDBD",
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`${styles.input} ${value ? styles.inputWithValue : ""}`}
            onFocus={handleFocus}
            required={required}
          />

          <label className={styles.label}>{label}</label>
          {type === "password" && (
            <div className={styles.image} onClick={togglePassword}>
              <img
                src={passwordShown ? passwordIcon : showPasswordIcon}
                className={styles.icon}
              />
            </div>
          )}
        </div>
        {showPasAcceptanceCrieteria && (
          <p
            className={
              error && error !== "This field is required."
                ? styles.passwordError
                : styles.passwordCriteria
            }
          >
            {error && error !== "This field is required."
              ? error
              : "At least 12 characters, including 1 uppercase, lowercase, number and special character."}
          </p>
        )}
      </p>
    </div>
  );
};
export default Input;
