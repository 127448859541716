import { useSigninMutation, useSocialLoginMutation } from "@/redux/api";
import { useMsal } from "@azure/msal-react";
import { useGoogleLogin } from "@react-oauth/google";
import Button from "@ui-kit/components/button/button.component";
import Input from "@ui-kit/components/input/input.component";
import Link from "@ui-kit/components/link/link.component";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import google_ic from "../../../../../assets/google-icon.svg";
import microsoft_ic from "../../../../../assets/Microsoft_Logo.svg";
import UseMediaQuery from "../media-query/media-query";
import styles from "./signin.module.scss";
import Cookies from "js-cookie";
import { useUserState, useUserStateActions } from "@/redux/userSlice";
import { useDispatch } from "react-redux";
const SignInWithEmail: React.FC = () => {
  const [SocialLogin] = useSocialLoginMutation();
  const [signIn] = useSigninMutation();
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const isMobileScreen = UseMediaQuery("(max-width: 360px)");
  const [formData, setFormData] = useState({ Email: "", Password: "" });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [formErrors, setFormErrors] = useState({
    Email: false,
    Password: false,
  });
  const [response, setResponse] = useState("");

  const handleChange = (
    name: string,
    value: string,
    error: string | boolean
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsButtonDisabled(false);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };
  const dispatch = useDispatch();
  const userState = useUserState();
  useEffect(() => {
    console.log(userState);
  }, [userState]);

  const userStateActions = useUserStateActions();
  const handleSubmit = async (event: React.FormEvent) => {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    event.preventDefault();
    if (
      formData.Email.match(isValidEmail) &&
      formData.Email.includes("@cinqd.com") &&
      formData.Password !== ""
    ) {
      try {
        const result: any = await signIn(formData);
        if (result?.data?.status === 200) {
          toast.success("Login Successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log(result.data.data);
          userStateActions.setUserData(result.data.data);
          const response = await axios.get(
            `https://backend-b.cinqd.com/setup/get-my-businesses/${result?.data?.data?.user?._id}`,
            {
              headers: {
                "auth-token": result?.data?.data?.token,
              },
            }
          );
          if (response.data.data.length === 0) {
            const requestModel = {
              ownerId: result?.data?.data?.user?._id,
              businessType: "sole",
              generalInformation: {
                businessName: "Draft Business",
              },
            };
            const response = await axios.post(
              `https://backend-b.cinqd.com/setup/create-business`,
              requestModel,
              {
                headers: {
                  "auth-token": result?.data?.data?.token,
                },
              }
            );
            if (response.data.status === 200) {
              const { setBusiness } = userStateActions; // Get the setBusiness action
              dispatch(setBusiness(response.data.data)); // Dispatch the action
              userStateActions.setBusiness(response.data.data);
              navigate(`/user/emails`);
              window.location.reload();
            } else {
              toast.error("Error creating business!");
            }
          } else {
            userStateActions.setBusiness(response.data.data[0]);
            navigate(`/user/emails`);
            window.location.reload();
          }
        } else {
          toast.error("Invalid Credentials!");
        }
      } catch (error) {
        // Handle errors (e.g., show an error message)
        console.log(error);
        throw new Error("error");
      }

      // Check if there are any validation errors before submitting
      const hasErrors = Object.values(formErrors).some(
        (error) => error !== false
      );
    } else {
      toast.error("Please enter a valid email and password!");
    }
  };
  const loginRequest = {
    auth: {
      auth: {
        clientId: "27aa1721-bb9e-4d8d-8d1f-fdf2b78353cb",
        authority:
          "https://login.microsoftonline.com/07eae7d1-de41-410c-aa48-12f192103ace",
      },
    },
    scopes: ["user.read"],
  };
  const handleMicrosoftLogin = () => {
    instance
      .loginPopup({ ...loginRequest, prompt: "create" })
      .then(async (response: any) => {
        const result: any = await SocialLogin({
          userName: response.account.name,
          Email: response.account.username,
          Password: response.uniqueId,
          SignupType: "microsoft",
        });
        if (result?.data?.status === 200) {
          toast.success("Login Successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate(`/user?${JSON.stringify(result.data.data)}`);
          window.location.reload();
        } else {
          toast.error("Invalid Credentials!");
        }
      })
      .catch((error: any) => {});
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then(async (response: any) => {
          const result: any = await SocialLogin({
            userName: response.data.name,
            Email: response.data.email,
            Password: response.data.name,
            SignupType: "google",
          });
          if (result?.data?.status === 200) {
            toast.success("Login Successfully!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            navigate(`/user?${JSON.stringify(result.data.data)}`);
            window.location.reload();
          } else {
            toast.error("Invalid Credentials!");
          }
        });
    },
  });
  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.heading}>
          <header className={styles.text}>Login</header>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.input}></div>
          <div
            className={styles.input}
            style={{ marginBottom: "-10px", marginTop: "-10px" }}
          >
            <Input
              label="Email"
              type="email"
              inputName="Email"
              placeholder="example@gmail.com"
              value={formData.Email}
              required
              onChange={handleChange}
              apiError={response}
            />
          </div>
          <div
            className={styles.input}
            style={{ marginBottom: isMobileScreen ? "-5px" : "0px" }}
          >
            <Input
              label="Password"
              type="password"
              inputName="Password"
              placeholder="Enter Password"
              value={formData.Password}
              required
              onChange={handleChange}
              apiError={response}
            />
            <div className={styles.forgotPassword}>
              <span
                className={styles.span}
                onClick={() => navigate("/recoverpassword")}
              >
                Forgot Password
              </span>
            </div>
          </div>

          <div className={styles.button}>
            {isButtonDisabled ? (
              <Button
                title="Login"
                type="submit"
                handler={() => {}}
                width={isMobileScreen ? "328px" : "328px"}
                radius={isMobileScreen ? "10px" : "8px"}
                isDisabled
                color="#BDBDBD"
                bgColor="#E0E0E0"
              />
            ) : (
              <Button
                title="Login"
                handler={() => {}}
                type="submit"
                width={isMobileScreen ? "328px" : "328px"}
                radius={isMobileScreen ? "10px" : "8px"}
                fontWeight="400"
                fontSize="16px"
              />
            )}
          </div>
        </form>
        <div className={styles.accountInfo}>
          <span
            className={styles.span}
            style={{ display: "inline-block", marginRight: "4px" }}
          >
            Don't have an account?{" "}
          </span>
          <Link
            onClick={() => navigate("/register")}
            textDecoration="underline"
            fontSize="12px"
          >
            Sign up
          </Link>
        </div>
        <div className={styles.orDiv}>
          <div className={styles.divider}></div>
          <div className={styles.orText}>Or</div>
          <div className={styles.divider}></div>
        </div>
        <div className={styles.socialButtons}>
          <button
            className={styles.button}
            onClick={handleMicrosoftLogin}
            style={{ gap: "12px" }}
          >
            <img
              src={microsoft_ic}
              alt="Microsoft Icon"
              className={styles.microsoft_ic}
            />
            <span>Continue With Microsoft</span>
          </button>
          <button
            className={styles.button}
            onClick={() => handleGoogleLogin()}
            style={{ gap: "12px" }}
          >
            <img
              src={google_ic}
              alt="Google Icon"
              className={styles.google_ic}
            />
            <span>Continue With Google</span>
          </button>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default SignInWithEmail;
