import React from "react";
import styles from "./link.module.scss";

interface LinkProps {
	children: React.ReactNode;
	fontSize?: string;
	color?: string;
	fontWeight?: string | number;
	textDecoration?: string;
	onClick: () => void;
}

const Link: React.FC<LinkProps> = ({
	children,
	color,
	fontWeight,
	textDecoration,
	fontSize,
	onClick,
}) => {
	const customStyle: React.CSSProperties = {
		color,
		fontWeight,
		textDecoration,
		display: "inline-flex",
		fontSize,
	};
	return (
		<div onClick={onClick} className={styles.link} style={customStyle}>
			{children}
		</div>
	);
};

export default Link;
