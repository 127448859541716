import './typography.styles.scss';
import React from "react";
interface HeadingThreeProps {
    title: string | number | boolean;
    fontSize?: string;
    lineHeight?: string | number;
    fontWeight?: string | number;
    fontFamily?: string;
    email?: string;
    color?: string
}
//styleName: Sub header/14 Semibold;
export const HeaderThree: React.FC<HeadingThreeProps> = ({ title,
    fontSize,
    lineHeight,
    fontWeight,
    fontFamily,
    email,
    color,
}) => {
    const customStyle: React.CSSProperties = {
        fontSize,
        lineHeight,
        fontWeight,
        fontFamily,
        color,
    };
    return (
        <>
            {!email ?
                <p className='header3' style={customStyle}>{title}</p>
                :
                <div className='divflex'>

                    <p className='header3email' style={customStyle}>{title}

                    </p>
                    <p className='emailText'>{email}</p>

                </div>

            }
        </>
    )
}