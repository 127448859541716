// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header1 {
  font-size: 32px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0.1px;
  text-align: center;
  color: rgb(51, 51, 51);
}

.header2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.1px;
  text-align: center;
}

.header3 {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: rgb(51, 51, 51);
  margin-top: 16px;
}

.header3email {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #4F4F4F;
  margin-top: 8px;
}

.divflex {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.emailText {
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0.1000000015px;
  text-align: left;
  margin-top: -15px;
  color: #828282;
}`, "",{"version":3,"sources":["webpack://./src/ui-kit/src/components/typography/typography.styles.scss"],"names":[],"mappings":"AACA;EAEA,eAAA;EACA,gBAAA;EACC,iBAAA;EACD,qBAAA;EACA,kBAAA;EACA,sBAAA;AADA;;AAMA;EAGI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,kBAAA;AALJ;;AAQI;EAGA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EAEA,sBAAA;EAEE,gBAAA;AATN;;AAYI;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EAGA,cAAA;EACA,eAAA;AAXN;;AAeI;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,uBAAA;AAZN;;AAgBI;EAGJ,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,8BAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AAfA","sourcesContent":["//Header/32\n.header1{\n//font-family: '';\nfont-size: 32px;\nfont-weight: 700;\n line-height: 48px;\nletter-spacing: 0.1px;\ntext-align: center;\ncolor: rgba(51, 51, 51, 1);\n// color: #4F4F4F;\n\n}\n\n.header2 {\n    //styleName: Header/24 Regular;\n    //font-family: Open Sans;\n    font-size: 24px;\n    font-weight: 400;\n    line-height: 32px;\n    letter-spacing: 0.1px;\n    text-align: center;\n }\n\n    .header3 {\n    //styleName: Sub header/14 Semibold;\n    //font-family: Open Sans;\n    font-size: 14px;\n    font-weight: 600;\n    line-height: 24px;\n    letter-spacing: -0.01em;\n   \n    color: rgba(51, 51, 51, 1);\n      // text-align: left;\n      margin-top: 16px;\n    }\n    \n    .header3email{\n      font-size: 14px;\n      font-weight: 600;\n      line-height: 24px;\n      letter-spacing: -0.01em;\n     \n      // color: rgba(51, 51, 51, 1);\n      color: #4F4F4F;\n      margin-top: 8px;\n      // text-align: left;\n    }\n\n    .divflex{\n      display: flex;\n      flex-direction: column;\n      align-items: flex-start;\n      justify-content: center;\n      \n    }\n\n    .emailText{\n      //styleName: Body/12 Light;\n// font-family: Roboto;\nfont-size: 12px;\nfont-weight: 300;\nline-height: 16px;\nletter-spacing: 0.10000000149011612px;\ntext-align: left;\nmargin-top: -15px;\ncolor: #828282;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
