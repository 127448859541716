import React, { useEffect, useState } from "react";
import Input from "@ui-kit/components/input/input.component";
import Link from "@ui-kit/components/link/link.component";
import Button from "@ui-kit/components/button/button.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./signup-by-invite.module.scss";
import UseMediaQuery from "../media-query/media-query";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const SignupByInvite: React.FC = () => {
  const navigate = useNavigate();
  const isMobileScreen = UseMediaQuery("(max-width: 360px)");
  const [isPassMatch, setIsPassMatch] = useState("Matched");
  const [business, setBusiness] = useState("");
  const [data, setData] = useState<any>();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    repeatPassword: "",
    invitationId: "",
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [formErrors, setFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    repeatPassword: false,
  });
  const [response, setResponse] = useState("");

  const handleChange = (
    name: string,
    value: string,
    error: string | boolean
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  useEffect(() => {
    //Getting the Current URL

    const currentUrl = window.location.href;

    //Using Regex to get the business ID

    const invitationIdRegex = /invitationId=([^&]+)/;
    const match = currentUrl.match(invitationIdRegex);
    if (match) {
      const urlInvite = decodeURIComponent(match[1]);
      axios
        .get(
          `https://backend-b.cinqd.com/invites/get-invitation-by-id/${urlInvite}`
        )
        .then((res) => {
          setFormData((prevData) => ({
            ...prevData,
            invitationId: urlInvite,
            firstName: res?.data?.data?.user?.firstName,
            lastName: res?.data?.data?.user?.lastName,
            email: res?.data?.data?.user?.Email,
          }));
          setBusiness(
            res?.data?.data?.businessId?.generalInformation?.businessName
          );
          setData(res?.data?.data);
        })
        .catch((e) => console.log(e));
    }
  }, []);

  useEffect(() => {
    const valuesNotEmpty = Object.values(formData).every(
      (value) => value.trim() !== ""
    );
    const hasErrors = Object.values(formErrors).some(
      (error) => error !== false
    );
    const passwordsMatch = formData.password === formData.repeatPassword;

    if (valuesNotEmpty && !hasErrors && passwordsMatch) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [formData]);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Check if there are any validation errors before submitting
    const hasErrors = Object.values(formErrors).some(
      (error) => error !== false
    );

    if (!hasErrors) {
      // Perform form submission
      console.log("Form submitted:", formData);
      if (formData.password === formData.repeatPassword) {
        setIsPassMatch("Matched");
      } else {
        setIsPassMatch("Not Matched");
        return;
      }
      let requestModel = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        Email: formData.email,
        Password: formData.password,
        confirmPassword: formData.repeatPassword,
        invitedBy: formData.invitationId,
        SignupType: "email",
      };
      try {
        const response: any = await fetch(
          "https://backend-a.cinqd.com/auth/sign-up",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestModel),
          }
        );

        if (response?.status === 200) {
          navigate("/auth/emailconfrimation", {
            state: {
              email: formData.email,
              successMessage: "Signup successfully!",
            },
          });
        } else if (response?.status === 400) {
          toast.error("Link expired!", {
            position: "top-right",
          });
        } else if (response?.status === 500) {
          toast.error("Internal server error!", {
            position: "top-right",
          });
        } else {
          console.log("Server Error");
        }

        console.log("status code", response.status);
      } catch (error) {
        // Handle errors (e.g., show an error message)
        console.error("Error during signup:", error);
      }
      setResponse("Wrong username and Password (e.g inCase of login)");
    } else {
      //console.log('Form contains errors. Please correct them before submitting.');
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={`${styles.heading} py-0 mb-2`}>
          <div className="header24bold ">You have been invited</div>
          <p className="body14regular mt-3">to collaborate on</p>
          <div className="d-flex justify-content-center align-items-center gap-2 mt-3">
            <p>
              {" "}
              <img
                src={data?.businessId?.businessImage}
                alt=""
                className="imgSize"
              />
            </p>
            <p className="body14regular">{business}</p>
          </div>
        </div>
        <div></div>
        <form onSubmit={handleSubmit}>
          <div className={styles.input}>
            <Input
              label="First name"
              type="text"
              inputName="firstName"
              placeholder="Enter first name"
              value={formData?.firstName}
              required
              minLength={3}
              maxLength={20}
              onChange={handleChange}
              disabled={true}
            />
          </div>
          <div className={styles.input}>
            <Input
              label="Last name"
              type="text"
              inputName="lastName"
              placeholder="Enter last name"
              value={formData?.lastName}
              required
              minLength={3}
              maxLength={20}
              onChange={handleChange}
              disabled={true}
            />
          </div>
          <div
            className={styles.input}
            style={{ marginBottom: "-10px", marginTop: "-10px" }}
          >
            <Input
              label="Email"
              type="email"
              inputName="email"
              placeholder="example@gmail.com"
              value={formData.email}
              required
              onChange={handleChange}
              disabled={true}
            />
          </div>
          <div
            className={styles.input}
            style={{ marginBottom: isMobileScreen ? "-5px" : "0px" }}
          >
            <Input
              label="Password"
              type="password"
              inputName="password"
              placeholder="Enter Password"
              value={formData.password}
              required
              minLength={12}
              showPasAcceptanceCrieteria
              IsPasswordMatch={isPassMatch}
              onChange={handleChange}

            //apiError={response}
            />
          </div>
          <div className={styles.input}>
            <Input
              label="Repeat Password"
              type="password"
              inputName="repeatPassword"
              placeholder="Enter Repeat Password"
              value={formData.repeatPassword}
              required
              minLength={12}
              IsPasswordMatch={isPassMatch}
              onChange={handleChange}
            //apiError={response}
            />
          </div>
          <div className={styles.privacyPolicy}>
            <p className="body10regular">
              By creating your account you agree to your personal data being
              stored and processed according to our <span className="body10regular color228dff">Privacy Policy</span>,{" "}
              <span className="body10regular color228dff">Terms & Conditions</span>, <span className="body10regular color228dff">Cookie Policy</span>
            </p>
          </div>
          <div className={styles.button}>
            {isButtonDisabled ? (
              <Button
                title="Sign up"
                type="submit"
                handler={() => { }}
                width={isMobileScreen ? "328px" : "328px"}
                radius={isMobileScreen ? "10px" : "8px"}
                isDisabled
                color="#BDBDBD"
                bgColor="#E0E0E0"
              />
            ) : (
              <Button
                title="Sign up"
                handler={() => { }}
                type="submit"
                width={isMobileScreen ? "328px" : "328px"}
                radius={isMobileScreen ? "10px" : "8px"}
              />
            )}
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default SignupByInvite;
