import { useForgotPasswordEmailMutation } from "@/redux/api";
import Box from "@ui-kit/components/box/box.component";
import Button from "@ui-kit/components/button/button.component";
import Input from "@ui-kit/components/input/input.component";
import { HeaderOne } from "@ui-kit/components/typography/Header-one.component";
import PlainText from "@ui-kit/components/typography/plain-text";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useMediaQuery from "../media-query/useMediaQuery";
import styles from "./recover-password-two.module.scss";
const RecoverPasswordTwo = () => {
  const [forgotPassword, { isLoading, isSuccess, isError }] =
    useForgotPasswordEmailMutation();
  const [formData, setFormData] = useState({ email: "" });
  const [formErrors, setFormErrors] = useState({ email: false });
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery("(max-width: 360px)");

  const handleChange = (
    name: string,
    value: string,
    error: string | boolean
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setIsButtonDisabled(false);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));

    console.log(formErrors.email);
  };

  const handleButtonClick = async () => {
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    if (formData.email.match(isValidEmail)) {
      try {
        await forgotPassword({
          Email: formData.email,
          Type: "forgot-Password",
        });

        if (isError) {
          throw new Error("Api failed");
        }
        toast.success("Please check your account to reset your password!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } catch (error) {
        console.error("Error during signup:", error);
      }
    } else {
      console.log("Please enter a valid email address");
      toast.error("Please enter valid email address!");
    }
  };

  return (
    <div>
      {isMobileScreen ? (
        <div className={styles.mobile_container}>
          <HeaderOne
            title="Recover Password"
            fontSize="24px"
            lineHeight="32px"
            textAlign="left"
          />

          <PlainText
            text='You will receive "Recover Password" link via email'
            lineHeight="16px"
            size="14px"
            color="rgba(51, 51, 51, 1)"
          />

          <Input
            type="email"
            label="Email"
            inputName="email"
            value={formData.email}
            onChange={handleChange}
          />

          <Button
            title="Send recover link"
            handler={handleButtonClick}
            color="rgba(189, 189, 189, 1)"
            bgColor="rgba(224, 224, 224, 1)"
            radius="8px"
            width={"100%"}
            type="button"
            isDisabled={isButtonDisabled}

            // You can customize other props here if needed
          />
        </div>
      ) : (
        <Box width={"auto"} height={"auto"}>
          <div className={styles.container}>
            <HeaderOne
              title="Recover Password"
              lineHeight={"20px"}
              fontWeight={"700"}
              fontSize="24px"
            />

            <PlainText
              text='You will receive "Recover Password" link via email'
              lineHeight="24px"
              size="14px"
              fontWeight="400"
              color="rgba(51, 51, 51, 1)"
            />

            <Input
              type="email"
              label="Email"
              inputName="email"
              value={formData.email}
              onChange={handleChange}
            />

            {/* <br />  */}

            {/* <Button
							title="Send recover link"
							handler={handleButtonClick}
							radius="8px"
							width={"328px"}
							type="button"

							// You can customize other props here if needed
						/> */}
            {isButtonDisabled ? (
              <Button
                title="Send recover link"
                type="submit"
                handler={() => {}}
                width={"328px"}
                radius={isMobileScreen ? "10px" : "8px"}
                isDisabled={isButtonDisabled}
                color="#BDBDBD"
                bgColor="#E0E0E0"
              />
            ) : (
              <Button
                title="Send recover link"
                handler={handleButtonClick}
                type="submit"
                width={"328px"}
                radius={isMobileScreen ? "10px" : "8px"}
              />
            )}
          </div>
        </Box>
      )}
      <ToastContainer />
    </div>
  );
};

export default RecoverPasswordTwo;
