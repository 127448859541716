// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.two-FA_container__g7NcY {
  text-align: center;
  margin-top: -30px;
  margin-bottom: -20px;
}

.two-FA_mobile_header__-TvBA {
  display: block;
}

.two-FA_mobile_content__hlYgD {
  padding: 18px;
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/screens/two-factor-authentication/two-FA.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,iBAAA;EACA,oBAAA;AACJ;;AAEI;EACI,cAAA;AACR;;AAEI;EACG,aAAA;EACA,gBAAA;AACP","sourcesContent":[".container {\n    text-align: center;\n    margin-top: -30px;\n    margin-bottom: -20px;\n    }\n\n    .mobile_header{\n        display: block;\n    }\n\n    .mobile_content {\n       padding: 18px;\n       text-align: left;\n  \n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `two-FA_container__g7NcY`,
	"mobile_header": `two-FA_mobile_header__-TvBA`,
	"mobile_content": `two-FA_mobile_content__hlYgD`
};
export default ___CSS_LOADER_EXPORT___;
