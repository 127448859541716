import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://backend-a.cinqd.com/"; // Change this to your API's base URL

export const ActivateAccApi = createApi({
  //generated reducers will be stored in store with this path..
  //when we have multiple type of apis..
  reducerPath: "ActivateAccApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  endpoints: (builder) => ({
    //end signin
    ActivateUserAccount: builder.mutation({
      query: (email) => ({
        url: `/auth/activate-user/${email}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useActivateUserAccountMutation } = ActivateAccApi;
