import React from "react";
import { Time } from "@ui-kit/components/icon/icon.component";
import Box from "@ui-kit/components/box/box.component";
import Button from "@ui-kit/components/button/button.component";
import "./Link_Expired.scss";
import useMediaQuery from "@ui-kit/hooks/useMediaQuery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Link_Expired = () => {
  const isMobile = useMediaQuery("(max-width: 360px) ");
  // const isMobile = useMediaQuery("(max-width: 360px) and (max-height: 1024px)");

  const handleButtonClick = async () => {
    // Code to execute when the button is clicked
    try {
      const response = await fetch(
        "http://ec2-3-8-34-71.eu-west-2.compute.amazonaws.com:8080/api/recoverPassword",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: "Muhammad.umar@cinqd.com",
        }
      );
      console.log(response.ok);
      if (!response.ok) {
        throw new Error("Api failed");
      }
      toast.success("Please check your account to reset your password!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error("Error during signup:", error);
    }
  };
  return (
    <div>
      {isMobile ? (
        <div className="mobile-container">
          {/* <p> mobile view</p> */}
          <div className="icon">
            <Time width={160} height={160} />
          </div>
          <p className="mobile-text">Link is expired, try again</p>
          <div className="mobile-button">
            <Button
              title="Resend Recover Password Link"
              width="328px"
              height="36px"
              padding="12px, 16px, 12px, 16px"
              handler={handleButtonClick}
              color="white"
              bgColor="#228DFF"
              radius="8px"
            />
          </div>
        </div>
      ) : (
        <Box width={"466px"} height={"350px"}>
          <Time width={200} height={200} />
          <p className="text">Link is expired, try again</p>
          <Button
            title="Resend Recover Password Link"
            width="420px"
            // padding="10px 20px 10px 10px"
            handler={handleButtonClick}
            color="white"
            bgColor="#228DFF"
            radius="8px"
            // You can customize other props here if needed
          />
        </Box>
      )}
    </div>
  );
};

export default Link_Expired;
