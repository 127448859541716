import React from "react"
import styles from './business-types.module.scss';
import { IonIcon } from "@ionic/react";
import {ReactComponent as MyLogo} from '../icons/property.svg';
interface BusinessTypesProps {
text: string;
icon: string;
color: string;
borderColor: string;

}


const BusinessTypes: React.FC<BusinessTypesProps> =({text, icon, color, borderColor}) => {
    
    const customStyle: React.CSSProperties = {
        color,   
        borderColor,
 };
    
    return(
        <div className={styles.container} style={{ borderColor: borderColor, borderWidth: '1px', borderStyle: 'solid' }}>
        <div className={''} >
            <div className={styles.icon} >
            <img src={icon}/>
            </div>
            {/* <br/> */}
           <p className={styles.text} style={customStyle}> {text} </p>
        </div>
        </div>
    )
}
export default BusinessTypes;