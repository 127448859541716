import { SVGProps } from "react"


interface styleProps extends SVGProps<SVGSVGElement> {
  width: number;
  height: number;
  
}
export const Icon = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.s3.org/2000/svg" viewBox="0 0 40 40 " {...props} >
        <path fill={props.fill} 
        d="M15.75 4.5V1.5C15.75 1.30109 15.671 1.11032 15.5303 0.96967C15.3897 0.829018 15.1989 0.75 15 0.75H9C8.80109 0.75 8.61032 0.829018 8.46967 0.96967C8.32902 1.11032 8.25 1.30109 8.25 1.5V4.5C7.25578 4.50112 6.3026 4.89656 5.59958 5.59958C4.89656 6.3026 4.50112 7.25578 4.5 8.25V15.75C4.50112 16.7442 4.89656 17.6974 5.59958 18.4004C6.3026 19.1034 7.25578 19.4989 8.25 19.5V22.5C8.25 22.6989 8.32902 22.8897 8.46967 23.0303C8.61032 23.171 8.80109 23.25 9 23.25H15C15.1989 23.25 15.3897 23.171 15.5303 23.0303C15.671 22.8897 15.75 22.6989 15.75 22.5V19.5C16.7442 19.4989 17.6974 19.1034 18.4004 18.4004C19.1034 17.6974 19.4989 16.7442 19.5 15.75V8.25C19.4989 7.25578 19.1034 6.3026 18.4004 5.59958C17.6974 4.89656 16.7442 4.50112 15.75 4.5V4.5ZM18.375 15C18.374 15.8948 18.0181 16.7527 17.3854 17.3854C16.7527 18.0181 15.8948 18.374 15 18.375H9C8.1052 18.374 7.24733 18.0181 6.61461 17.3854C5.98189 16.7527 5.62599 15.8948 5.625 15V9C5.62599 8.1052 5.98189 7.24733 6.61461 6.61461C7.24733 5.98189 8.1052 5.62599 9 5.625H15C15.8948 5.62599 16.7527 5.98189 17.3854 6.61461C18.0181 7.24733 18.374 8.1052 18.375 9V15Z" 
             />
    </svg>
);

export const Check = (props: styleProps) => (
<svg width={props.width} height={props.height} viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Icons / General">
<path id="Icon" d="M175 100C175 141.421 141.421 175 100 175C58.5786 175 25 141.421 25 100C25 58.5786 58.5786 25 100 25C111.767 25 122.901 27.7099 132.812 32.5397M160.938 53.125L95.3125 118.75L76.5625 100" stroke="#228DFF" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
);

export const Email = (props: styleProps) =>(
  <svg width={props.width} height={props.height} viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39.0625 56.2498L94.6637 96.1686C97.8741 98.4735 102.126 98.4735 105.336 96.1686L160.938 56.2498M43.75 158.333H156.25C166.605 158.333 175 149.628 175 138.889V61.1109C175 50.3721 166.605 41.6665 156.25 41.6665H43.75C33.3947 41.6665 25 50.3721 25 61.1109V138.889C25 149.628 33.3947 158.333 43.75 158.333Z" stroke="#228DFF" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)



export const Time = (props: styleProps) => (
  <svg width={props.width} height={props.height} viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M131.026 122.545C133.122 123.243 135.387 122.111 136.086 120.015C136.784 117.919 135.652 115.654 133.556 114.955L131.026 122.545ZM104.166 109.375H100.166C100.166 111.097 101.268 112.625 102.901 113.17L104.166 109.375ZM108.166 70.1739C108.166 67.9648 106.375 66.1739 104.166 66.1739C101.957 66.1739 100.166 67.9648 100.166 70.1739H108.166ZM133.556 114.955L105.431 105.58L102.901 113.17L131.026 122.545L133.556 114.955ZM108.166 109.375V70.1739H100.166V109.375H108.166ZM175.166 100C175.166 139.212 143.378 171 104.166 171V179C147.797 179 183.166 143.631 183.166 100H175.166ZM104.166 171C64.9538 171 33.166 139.212 33.166 100H25.166C25.166 143.631 60.5355 179 104.166 179V171ZM33.166 100C33.166 60.7878 64.9538 29 104.166 29V21C60.5355 21 25.166 56.3695 25.166 100H33.166ZM104.166 29C143.378 29 175.166 60.7878 175.166 100H183.166C183.166 56.3695 147.797 21 104.166 21V29Z" fill="#228DFF"/>
</svg>

)