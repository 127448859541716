import React, { useState } from "react";
import styles from './two-FA.module.scss';
import Box from "@ui-kit/components/box/box.component";
import { HeaderOne } from "@ui-kit/components/typography/Header-one.component";
import PlainText from "@ui-kit/components/typography/plain-text";
import Input from "@ui-kit/components/input/input.component";
import Button from "@ui-kit/components/button/button.component";

import useMediaQuery from "../media-query/useMediaQuery";
const TwoFAAuthentication =() => {

      const [formData, setFormData] = useState({ code: ''});
      const [formErrors, setFormErrors] = useState({email: false});
      
      const isMobileScreen = useMediaQuery('(max-width: 360px)');
      const handleChange = (name : string, value : string, error : string | boolean) => {
          setFormData((prevData) => ({
              ...prevData,
              [name]: value
          }));
          setFormErrors((prevErrors) => ({
              ...prevErrors,
              [name]: error
          }));

          console.log(formErrors.email)
      };
     // className={styles.mobile_container}  style ={{  width:"328px"}}
      const handleButtonClick = () =>{}
    return (
        <div>
           
           { isMobileScreen? 
            <div className={styles.mobile_content}>
              
             
                <HeaderOne title="2-factor authenticator" fontSize="24px" lineHeight="32px"
                textAlign="left"
                />  
            
                <PlainText text='Please enter code from Google 2-factor authenticator' 
                lineHeight="16px" 
                size="14px"
                color="rgba(51, 51, 51, 1)" />
               
            <div >   
               <Input type="number" 
                 label="Verification Code"
                 inputName="code"
                 value={formData.code}
                onChange={handleChange}
                  />   
           
            </div>

        <Button
        title="Verify and Login"
        handler={handleButtonClick}
        color="rgba(189, 189, 189, 1)"
        isDisabled= {true}
        bgColor="rgba(224, 224, 224, 1)"
        radius="8px"
        width="92vw"
        type='button'
        
        // You can customize other props here if needed
      />
  
            </div>
           
           
           :
           
            <Box width="auto" height="auto">
            <div className={styles.container}>
            <HeaderOne lineHeight={'20px'} fontWeight={"700"} fontSize='24px' title="2-factor authenticator"/>
            <PlainText text='Please, enter code from Google 2-factor' size="14px" 
            lineHeight="16px"
            color="rgba(51, 51, 51, 1)"
            />

        <PlainText text='Authenticator' size="14px" 
            lineHeight="16px"
            color="rgba(51, 51, 51, 1)"
            />
           
            <br />
           
            {/* style={{width:"328px"}}  */}
           <div > 
            <Input type="number" 
             label="Verification Code"
             inputName="code"
             value={formData.code}
             onChange={handleChange}
            />
            </div>
            {/* <br />   */}

        <Button
        title="Verify and Login"
        handler={handleButtonClick}
        color="rgba(189, 189, 189, 1)"
        isDisabled= {true}
        bgColor="rgba(224, 224, 224, 1)"
        radius="8px"
        width=" 328px "
        type='button'
        
        // You can customize other props here if needed
      />

            </div>

            </Box>
}
        </div>
    )
}

export default TwoFAAuthentication;
