import Box from "@ui-kit/components/box/box.component";
import "./Email_Confirmation.scss";
import useMediaQuery from "@ui-kit/hooks/useMediaQuery";
import { Email } from "@ui-kit/components/icon/icon.component";
import Link from "@ui-kit/components/link/link.component";
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";

const Email_Confirmation = () => {

	const location = useLocation();
    const successMessage = location.state?.successMessage;

	useEffect(() => {
        if (successMessage) {
            // Show toast notification
            toast.success(successMessage);
        }
    }, [successMessage]);


	const HandleResend = () => {
		//Here a send Email request would be called
		console.log("Button clicked!");
	};

	const isMobile = useMediaQuery("(max-width: 425px)");
	console.log();

	const PaddingValues = {
		default: "14px, 14px",
		small: "14px, 14px",
		medium: "14px, 14px",
		large: "14px, 14px",
	};

	return (
		<div>
			<Box width={"460px"} height={"390px"} padding={PaddingValues}>
				<Email width={200} height={250} />

				<p
					className="text"
					style={{ width: `${isMobile ? "328px" : "454px"}` }}
				>
					Please, open your email and confirm your account
				</p>
				<p className="link_text">
					Didn't receive an email?&nbsp;
					<Link
						onClick={HandleResend}
						fontSize="14px"
						color="#228DFF"
						fontWeight="normal"
						textDecoration="underline"
					>
						Resend
					</Link>
				</p>
			</Box>
			<ToastContainer />
		</div>
	);
};

export default Email_Confirmation;
