import {
  useSigninMutation,
  useSignupMutation,
  useSocialLoginMutation,
} from "@/redux/api";
import { useMsal } from "@azure/msal-react";
import { useGoogleLogin } from "@react-oauth/google";
import Button from "@ui-kit/components/button/button.component";
import Input from "@ui-kit/components/input/input.component";
import Link from "@ui-kit/components/link/link.component";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import google_ic from "../../../../../assets/google-icon.svg";
import microsoft_ic from "../../../../../assets/Microsoft_Logo.svg";
import UseMediaQuery from "../media-query/media-query";
import styles from "./signup.module.scss";
import axios from "axios";
import PasswordMatchError from "@/app/auth/components/PasswordMatchError";
import Cookies from "js-cookie";
const SignUpWithEmail: React.FC = () => {
  const [
    SocialLogin,
    {
      data: socialsignInData,
      isLoading: socialSignInLoading,
      isSuccess: socialSignInSuccess,
    },
  ] = useSocialLoginMutation();
  const [
    signIn,
    { data: signInData, isLoading: SignInLoading, isSuccess: SignInSuccess },
  ] = useSigninMutation();
  const navigate = useNavigate();
  const isMobileScreen = UseMediaQuery("(max-width: 360px)");
  const [isPassMatch, setIsPassMatch] = useState("");
  const { instance, accounts } = useMsal();
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    password: "",
    repeatPassword: "",
  });

  const [formErrors, setFormErrors] = useState({
    fullName: false,
    email: false,
    password: false,
    repeatPassword: false,
  });
  const [response, setResponse] = useState("");

  const handleChange = (
    name: string,
    value: string,
    error: string | boolean
  ) => {
    let emailError = false;

    // Additional email validation using a regular expression
    if (name === "email") {
      const emailPattern =
        /^(?=.{1,255}$)(?!.*\.{2})[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

      emailError = !emailPattern.test(value);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: name === "email" ? emailError : error,
    }));
  };

  //API
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    const valuesNotEmpty = Object.values(formData).every(
      (value) => value.trim() !== ""
    );
    const hasErrors = Object.values(formErrors).some(
      (error) => error !== false
    );
    if (valuesNotEmpty && !hasErrors) {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  }, [formData]);

  const [signup, { isLoading, status }] = useSignupMutation();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Check if there are any validation errors before submitting
    const hasErrors = Object.values(formErrors).some(
      (error) => error !== false
    );

    if (!hasErrors) {
      if (formData.password === formData.repeatPassword) {
        setIsPassMatch("Matched");
      } else {
        setIsPassMatch("Not Matched");
        return;
      }
      let requestModel = {
        userName: formData.fullName,
        Email: formData.email,
        Password: formData.password,
        confirmPassword: formData.repeatPassword,
        SignupType: "email",
        referredBy: "",
      };

      try {
        const result = await signup(requestModel);
        if (isLoading) {
          setIsButtonDisabled(true);
        }

        if ("data" in result) {
          const responseData = result.data;
          if (responseData) {
            navigate("/auth/emailconfrimation", {
              state: { email: formData.email },
            });
          }
        } else {
          toast.error("Email Already Exists!", {
            position: "top-right",
          });
        }
      } catch (error) {
        toast.error("Email Already Exists!", {
          position: "top-right",
        });
      }
    }
  };
  const loginRequest = {
    auth: {
      auth: {
        clientId: "27aa1721-bb9e-4d8d-8d1f-fdf2b78353cb",
        authority:
          "https://login.microsoftonline.com/07eae7d1-de41-410c-aa48-12f192103ace",
      },
    },
    scopes: ["user.read"],
  };
  const handleMicrosoftLogin = () => {
    instance
      .loginPopup({ ...loginRequest, prompt: "create" })
      .then(async (response: any) => {
        const result: any = await SocialLogin({
          userName: response.account.name,
          Email: response.account.username,
          Password: response.uniqueId,
          SignupType: "microsoft",
        });
        if (result?.data?.status === 200) {
          toast.success("Login Successfully!", {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate(`/user?${JSON.stringify(result.data.data)}`);
          window.location.reload();
        } else {
          toast.error("Invalid Credentials!");
        }
      })
      .catch((error: any) => {});
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      // fetching userinfo can be done on the client or the server
      axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then(async (response) => {
          const result: any = await SocialLogin({
            userName: response.data.name,
            Email: response.data.email,
            Password: response.data.name,
            SignupType: "google",
          });

          if (result?.data?.status === 200) {
            toast.success("Login Successfully!", {
              position: toast.POSITION.TOP_RIGHT,
            });
            navigate(`/user?${JSON.stringify(result.data.data)}`);
            window.location.reload();
          } else {
            toast.error("Invalid Credentials!");
          }
        });
    },
  });

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.heading}>
          <div className={styles.text}>Create account</div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className={styles.input}>
            <Input
              label="Full name"
              type="text"
              inputName="fullName"
              placeholder="Enter Full name"
              value={formData.fullName}
              required
              minLength={3}
              maxLength={20}
              onChange={handleChange}
              //apiError={response}
            />
          </div>
          <div
            className={styles.input}
            style={{ marginBottom: "-10px", marginTop: "-10px" }}
          >
            <Input
              label="Email"
              type="email"
              inputName="email"
              placeholder="example@gmail.com"
              value={formData.email}
              required
              onChange={handleChange}
              //apiError={response}
            />
          </div>
          <div
            className={styles.input}
            style={{ marginBottom: isMobileScreen ? "-5px" : "0px" }}
          >
            <Input
              label="Password"
              type="password"
              inputName="password"
              placeholder="Enter Password"
              value={formData.password}
              required
              minLength={10}
              showPasAcceptanceCrieteria
              IsPasswordMatch={isPassMatch}
              onChange={handleChange}
              //apiError={response}
            />
          </div>
          <div className={styles.input} style={{ position: "relative" }}>
            <Input
              label="Repeat Password"
              type="password"
              inputName="repeatPassword"
              placeholder="Enter Repeat Password"
              value={formData.repeatPassword}
              required
              minLength={10}
              IsPasswordMatch={isPassMatch}
              onChange={handleChange}
              //apiError={response}
            />
            <PasswordMatchError isPassMatch={isPassMatch} />
          </div>
          <div className={styles.privacyPolicy}>
            <span className={styles.span}>
              By creating your account you agree to your personal data being
              stored and processed according to our{" "}
              <Link onClick={() => {}}>Privacy Policy</Link>,{" "}
              <Link onClick={() => {}}>Terms & Conditions</Link>,{" "}
              <Link onClick={() => {}}>Cookie Policy</Link>
            </span>
          </div>
          <div className={styles.button}>
            {isButtonDisabled ? (
              <Button
                title="Create Account"
                type="submit"
                handler={() => {}}
                width={isMobileScreen ? "328px" : "328px"}
                radius={isMobileScreen ? "10px" : "8px"}
                // isDisabled ={isLoading}
                color="#BDBDBD"
                bgColor="#E0E0E0"
              />
            ) : (
              <Button
                title={isLoading ? "Loading.." : "Create Account"}
                // isDisabled ={isLoading}
                handler={() => {}}
                type="submit"
                // color = {isLoading ? "#BDBDBD" : "primary"}
                bgColor={isLoading ? "#BDBDBD" : "var(--blue-05, #228DFF)"}
                width={isMobileScreen ? "328px" : "328px"}
                radius={isMobileScreen ? "10px" : "8px"}
              />
            )}
          </div>
          {/* <button onClick={Signup}>sample</button> */}
        </form>
        <div className={styles.accountInfo}>
          <span className={styles.span}>Already have an account? </span>
          <Link onClick={() => navigate("/login")} fontSize="12px">
            Login
          </Link>
        </div>
        <div className={styles.orDiv}>
          <div className={styles.divider}></div>
          <div className={styles.orText}>Or</div>
          <div className={styles.divider}></div>
        </div>
        <div className={styles.socialButtons}>
          <button
            className={styles.button}
            onClick={() => handleMicrosoftLogin()}
            style={{gap:"12px"}}
          >
            <img
              src={microsoft_ic}
              alt="Google Icon"
              className={styles.microsoft_ic}
            />
            <span>Continue With Microsoft</span>
          </button>
          <button className={styles.button} onClick={() => handleGoogleLogin()} style={{gap:"12px"}}>
            <img
              src={google_ic}
              alt="Google Icon"
              className={`${styles.google_ic}`}
            />
            <span>Continue With Google</span>
          </button>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default SignUpWithEmail;
