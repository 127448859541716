// src/Text.tsx
import React from "react";

interface PlainTextProps {
  text?: string;
  color?: string;
  size?: string;
  fontStyle?: "normal" | "italic" | "oblique";
  fontFamily?: string;
  lineHeight?: string;
  fontWeight?: string;
  textDecoration?: "none" | "underline";
  display?: "block" | "inline" | "inline-block";
  letterSpacing?: string;
  // Add any other necessary text-related props here
}

const PlainText: React.FC<PlainTextProps> = ({
  text = "",
  color = "var(--gray-black, #333)",
  size = "16px",
  fontStyle = "normal",
  fontFamily = "Roboto",
  lineHeight = "16",
  fontWeight = "400",
  textDecoration = "none",
  display = "block",
  letterSpacing = "0.1px",
  // Add any other necessary text-related props here
}) => {
  const textStyle: React.CSSProperties = {
    color,
    fontSize: size,
    fontStyle,
    lineHeight,
    fontWeight,
    textDecoration,
    display,
    fontFamily,
    letterSpacing,
    // Add any other necessary text-related styles here
  };

  return <span style={textStyle}>{text}</span>;
};

export default PlainText;
