// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recover-password-two_container__ooxmL {
  text-align: left;
  margin-top: -30px;
  margin-bottom: -20px;
}

.recover-password-two_mobile_container__nSHk4 {
  text-align: left;
  padding: 18px;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/screens/recover-password/recover-password-two.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,iBAAA;EACA,oBAAA;AACJ;;AAEI;EACI,gBAAA;EACA,aAAA;AACR","sourcesContent":[".container {\n    text-align: left;\n    margin-top: -30px;\n    margin-bottom: -20px;\n    }\n\n    .mobile_container{\n        text-align: left; \n        padding: 18px;\n\n    }\n\n    "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `recover-password-two_container__ooxmL`,
	"mobile_container": `recover-password-two_mobile_container__nSHk4`
};
export default ___CSS_LOADER_EXPORT___;
