import './typography.styles.scss';
import React from "react";

interface HeadingProps {   
    title: string;
    fontSize?: string;
    lineHeight?: string | number;
    fontWeight?: string | number;
    fontFamily?: string;
    textAlign?: "left" | "right" | "center";
}
//Header/32
export const HeaderOne :React.FC<HeadingProps> =({title, 
    fontSize, 
    lineHeight, 
    fontWeight, 
    fontFamily,
    textAlign,
}) =>
    {
     const customStyle: React.CSSProperties = {
            fontSize,
            lineHeight,
            fontWeight,
            fontFamily,
            textAlign,
     };
    return (
    <p className='header1' style={customStyle}>{title}</p>
    )
}



