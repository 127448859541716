import React, { useEffect, useState } from "react";
import Input from "@ui-kit/components/input/input.component";
import PlainText from "@ui-kit/components/typography/plain-text";
import Link from "@ui-kit/components/link/link.component";
import Button from "@ui-kit/components/button/button.component";
import google_ic from "../../../../../assets/google_ic.png";
import microsoft_ic from "../../../../../assets/microsoft_ic.png";
import styles from "./referral-signup.module.scss";
import UseMediaQuery from "../media-query/media-query";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
const ReferralSignup: React.FC = () => {
	const navigate = useNavigate();
	const isMobileScreen = UseMediaQuery("(max-width: 360px)");
	const [isPassMatch, setIsPassMatch] = useState("Matched");
	const [formData, setFormData] = useState({
		fullName: "",
		email: "",
		password: "",
		repeatPassword: "",
	});
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);

	const [formErrors, setFormErrors] = useState({
		fullName: false,
		email: false,
		password: false,
		repeatPassword: false,
	});
	const [response, setResponse] = useState("");

	const handleChange = (
		name: string,
		value: string,
		error: string | boolean
	) => {
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
		setFormErrors((prevErrors) => ({
			...prevErrors,
			[name]: error,
		}));
	};

	useEffect(() => {
		const valuesNotEmpty = Object.values(formData).every(
			(value) => value.trim() !== ""
		);
		const hasErrors = Object.values(formErrors).some(
			(error) => error !== false
		);
		if (valuesNotEmpty && !hasErrors) {
			setIsButtonDisabled(false);
		} else {
			setIsButtonDisabled(true);
		}
	}, [formData]);

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		// Check if there are any validation errors before submitting
		const hasErrors = Object.values(formErrors).some(
			(error) => error !== false
		);

		if (!hasErrors) {
			// Perform form submission
			//console.log('Form submitted:', formData);
			if (formData.password === formData.repeatPassword) {
				setIsPassMatch("Matched");
			} else {
				setIsPassMatch("Not Matched");
				return;
			}
			let requestModel = {
				userName: formData.fullName,
				email: formData.email,
				password: formData.password,
				confirmPassword: formData.repeatPassword,
				businesId: 0,
			};
			try {
				const response = await fetch(
					"http://ec2-3-8-34-71.eu-west-2.compute.amazonaws.com:8080/signup",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify(requestModel),
					}
				);
				// console.log("response", response);

				if (!response.ok) {
					throw new Error("Signup failed");
				}

				// Handle successful signup (e.g., show a success message)
				toast.success("Signup Successfully!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				navigate("/auth/emailconfrimation", {
					state: { email: formData.email },
				});
			} catch (error) {
				// Handle errors (e.g., show an error message)
				console.error("Error during signup:", error);
			}
			setResponse("Wrong username and Password (e.g inCase of login)");
		} else {
			//console.log('Form contains errors. Please correct them before submitting.');
		}
	};
	return (
		<div className={styles.container}>
			<div className={styles.innerContainer}>
				<div className={styles.heading}>
					<div className={styles.text}>Create account</div>
				</div>
				<div className={styles.referralInfo}>
					<PlainText
						text="This is a referral invitation from"
						size={isMobileScreen ? "14px" : "12px"}
						lineHeight={isMobileScreen ? "24px" : "16px"}
					/>
					<PlainText
						fontWeight="bold"
						text="{username}"
						size={isMobileScreen ? "14px" : "12px"}
						lineHeight={isMobileScreen ? "24px" : "16px"}
					/>
				</div>
				<form onSubmit={handleSubmit}>
					<div className={styles.input}>
						<Input
							label="Full name"
							type="text"
							inputName="fullName"
							placeholder="Enter Fullname"
							value={formData.fullName}
							required
							minLength={3}
							maxLength={20}
							onChange={handleChange}
							//apiError={response}
						/>
					</div>
					<div
						className={styles.input}
						style={{ marginBottom: "-10px", marginTop: "-10px" }}
					>
						<Input
							label="Email"
							type="email"
							inputName="email"
							placeholder="example@gmail.com"
							value={formData.email}
							required
							onChange={handleChange}
							//apiError={response}
						/>
					</div>
					<div
						className={styles.input}
						style={{ marginBottom: isMobileScreen ? "-5px" : "0px" }}
					>
						<Input
							label="Password"
							type="password"
							inputName="password"
							placeholder="Enter Password"
							value={formData.password}
							required
							minLength={12}
							showPasAcceptanceCrieteria
							IsPasswordMatch={isPassMatch}
							onChange={handleChange}
							//apiError={response}
						/>
					</div>
					<div className={styles.input}>
						<Input
							label="Repeat Password"
							type="password"
							inputName="repeatPassword"
							placeholder="Enter Repeat Password"
							value={formData.repeatPassword}
							required
							minLength={12}
							IsPasswordMatch={isPassMatch}
							onChange={handleChange}
							//apiError={response}
						/>
					</div>
					<div className={styles.privacyPolicy}>
						<span className={styles.span}>
							By creating your account you agree to your personal data being
							stored and processed according to our{" "}
						</span>
						<Link onClick={() => {}}>Privacy Policy</Link>
					</div>
					<div className={styles.button}>
						{isButtonDisabled ? (
							<Button
								title="Create Account"
								type="submit"
								handler={() => {}}
								width={isMobileScreen ? "328px" : "328px"}
								radius={isMobileScreen ? "10px" : "8px"}
								isDisabled
								color="#BDBDBD"
								bgColor="#E0E0E0"
							/>
						) : (
							<Button
								title="Create Account"
								handler={() => {}}
								type="submit"
								width={isMobileScreen ? "328px" : "328px"}
								radius={isMobileScreen ? "10px" : "8px"}
							/>
						)}
					</div>
				</form>
				<div className={styles.accountInfo}>
					<span className={styles.span}>Already have an account? </span>
					<Link onClick={() => {}}>Login</Link>
				</div>
				<div className={styles.orDiv}>
					<div className={styles.divider}></div>
					<div className={styles.orText}>Or</div>
					<div className={styles.divider}></div>
				</div>
				<div className={styles.socialButtons}>
					<button className={styles.button}>
						<img
							src={microsoft_ic}
							alt="Google Icon"
							className={styles.microsoft_ic}
						/>
						<span>Continue With Microsoft</span>
					</button>
					<button className={styles.button}>
						<img
							src={google_ic}
							alt="Google Icon"
							className={styles.google_ic}
						/>
						<span>Continue With Google</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default ReferralSignup;
