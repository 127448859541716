// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.1px;
  padding-bottom: 4px;
}

.mobile-text {
  width: 328px;
  height: 64px;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.1000000015px;
  text-align: center;
}

.icon {
  padding: 20px;
  margin-bottom: -20px;
  gap: 24px;
}

.mobile-container-confirmation {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 640px;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
}

.container {
  width: 100%;
  margin: 0 auto;
}
@media (max-width: 360px) {
  .container {
    max-width: 100%; /* Full width on mobile */
  }
}`, "",{"version":3,"sources":["webpack://./src/app/auth/screens/auth/recover-password/Password_Changed.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,qBAAA;EACA,mBAAA;AACF;;AAEA;EACE,YAAA;EACA,YAAA;EAEA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,8BAAA;EACA,kBAAA;AAAF;;AAGA;EACE,aAAA;EACA,oBAAA;EAEA,SAAA;AADF;;AAIA;EACE,aAAA;EACA,sBAAA;EACA,WAAA;EACA,aAAA;EACA,yBAAA;EACA,uBAAA;EACA,mBAAA;AADF;;AAIA;EACE,WAAA;EACA,cAAA;AADF;AAEE;EAHF;IAII,eAAA,EAAA,yBAAA;EACF;AACF","sourcesContent":[".text {\n  color: #000;\n  text-align: center;\n  font-size: 24px;\n  font-weight: 400;\n  line-height: 32px;\n  letter-spacing: 0.1px;\n  padding-bottom: 4px;\n}\n\n.mobile-text {\n  width: 328px;\n  height: 64px;\n\n  font-size: 24px;\n  font-weight: 400;\n  line-height: 32px;\n  letter-spacing: 0.10000000149011612px;\n  text-align: center;\n}\n\n.icon {\n  padding: 20px;\n  margin-bottom: -20px;\n  // margin-top: 200px;\n  gap: 24px;\n}\n\n.mobile-container-confirmation {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 640px;\n  background-color: #ffffff;\n  justify-content: center;\n  align-items: center;\n}\n\n.container {\n  width: 100%;\n  margin: 0 auto;\n  @media (max-width: 360px) {\n    max-width: 100%; /* Full width on mobile */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
