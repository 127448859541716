import React from "react";
import Box from "@ui-kit/components/box/box.component";
import { Icon_With_Text } from "@ui-kit/components/Icon With Text/Icon_with_text.component";
import { HeaderOne } from "@ui-kit/components/typography/Header-one.component";
import Button from "@ui-kit/components/button/button.component";
import styles from "./sign-in-by-Invite-one.module.scss";

import useMediaQuery from "../media-query/useMediaQuery";
const SignInByInviteOne: React.FC = () => {
	const isMobileScreen = useMediaQuery("max-width: 360px");

	// On click  method for button
	const handleButtonClick = () => {};

	return (
		<div>
			{isMobileScreen ? (
				<div className={styles.mobile_content}>
					<HeaderOne
						lineHeight={"32px"}
						title="You have been invited"
						fontSize="24px"
						textAlign="left"
					/>

					<span className={styles.text}>
						<span className={styles.text_bold}>Name Surname</span> invited you
						to collaborate on
						<span className={styles.text_bold}> "Project name"</span>
					</span>
					<br />
					<br />

					<Icon_With_Text text="Project Name" width="24px" height="24px" />

					<br />

					<Button
						title="Accept Invite"
						handler={handleButtonClick}
						color="white"
						bgColor="rgba(34, 141, 255, 1)"
						radius="8px"
						width="92vw"
						type="button"
						// You can customize other props here if needed
					/>

					<br />
					<br />

					<Button
						title="Reject Invite"
						handler={handleButtonClick}
						color="rgba(34, 141, 255, 1)"
						bgColor="white"
						radius="8px"
						width="92vw"
						type="button"
						// You can customize other props here if needed
					/>
				</div>
			) : (
				<Box width="auto" height="auto">
					<div className={styles.container}>
						{/* Heading */}
						<HeaderOne
							lineHeight={"20px"}
							fontWeight={"700"}
							fontSize="24px"
							title="You have been invited"
						/>

						<span className={styles.text}>
							<span className={styles.text_bold}>Name Surname</span> invited you
							to collaborate on{" "}
							<span className={styles.text_bold}>
								<br />
								"Project name"
							</span>
						</span>

						<br />

						{/* Component that displays Project Name and a Icon that contains first letter of the first two words of the project*/}
						<div className={styles.icon}>
							<Icon_With_Text text="Project Name" width="10px" height="10px" />
						</div>

						<Button
							title="Accept Invite"
							handler={handleButtonClick}
							color="white"
							bgColor="rgba(34, 141, 255, 1)"
							radius="8px"
							width="328px"
							type="button"

							// You can customize other props here if needed
						/>

						<br />

						<Button
							title="Reject Invite"
							handler={handleButtonClick}
							color="rgba(34, 141, 255, 1)"
							bgColor="white"
							radius="8px"
							width="328px "
							type="button"
							// You can customize other props here if needed
						/>
					</div>
				</Box>
			)}
		</div>
	);
};

export default SignInByInviteOne;
