import { useSetNewPasswordMutation } from "@/redux/api";
import Button from "@ui-kit/components/button/button.component";
import Input from "@ui-kit/components/input/input.component";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UseMediaQuery from "../media-query/media-query";
import styles from "./set-new-password.module.scss";
import PasswordMatchError from "@/app/auth/components/PasswordMatchError";
const SetNewPassword: React.FC = () => {
	const [SetNewPassword, { isLoading, isSuccess, isError }] =
		useSetNewPasswordMutation();
	const navigate = useNavigate();
	const isMobileScreen = UseMediaQuery("(max-width: 360px)");
	const [isPassMatch, setIsPassMatch] = useState("");
	const [formData, setFormData] = useState({
		id: "",
		password: "",
		repeatPassword: "",
	});
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);

	const [formErrors, setFormErrors] = useState({
		password: false,
		repeatPassword: false,
	});
	const [response, setResponse] = useState("");
	const handleChange = (
		name: string,
		value: string,
		error: string | boolean
	) => {
		setIsButtonDisabled(false);
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
		setFormErrors((prevErrors) => ({
			...prevErrors,
			[name]: error,
		}));
	};

	// useEffect(() => {
	// 	const valuesNotEmpty = Object.values(formData).every(
	// 		(value) => value.trim() !== ""
	// 	);
	// 	const hasErrors = Object.values(formErrors).some(
	// 		(error) => error !== false
	// 	);
	// 	// if (valuesNotEmpty && isPassMatch=="Matched" && !hasErrors) {
	// 	//   setIsButtonDisabled(false);

	// 	// } else {
	// 	//   setIsButtonDisabled(true);
	// 	// }
	// 	if (formData.password === formData.repeatPassword) {
	// 		// setIsPassMatch("Matched");
	// 		setpwMatched(true);
	// 		console.log(pwMatched, "state of pwMatched");
	// 		if (pwMatched && !hasErrors) {
	// 			setIsButtonDisabled(false);
	// 			// Perform form submission
	// 			//console.log('Form submitted:', formData);
	// 			// if (formData.password === formData.repeatPassword && formData.password !=="" && formData.repeatPassword!=="") {
	// 			//   setIsPassMatch("Matched");
	// 			//   setIsButtonDisabled(false);
	// 			// } else {
	// 			//   setIsPassMatch("Not Matched");
	// 			//   setIsButtonDisabled(true);
	// 			//   return;
	// 			// }
	// 		} else {
	// 			setIsButtonDisabled(true);
	// 		}
	// 	} else {
	// 		setIsPassMatch("Not Matched");
	// 	}
	// 	console.log("button", isButtonDisabled);
	// 	console.log("Values", valuesNotEmpty);
	// }, [formData]);

	useEffect(() => {
		//Getting the Current URL

		const currentUrl = window.location.href;

		//Using Regex to verify the email

		const userIdRegex = /id=([^&]+)/;
		const match = currentUrl.match(userIdRegex);
		if (match) {
			const urlUserId = decodeURIComponent(match[1]);
			setFormData((prevData) => ({
				...prevData,
				id: urlUserId,
			}));
		}
	}, []);

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		if (
			formData.password === formData.repeatPassword &&
			formData.password !== "" &&
			formData.repeatPassword !== ""
		) {
			setIsPassMatch("Matched");
			// setIsButtonDisabled(false);
		} else {
			setIsPassMatch("Not Matched");

			return;
		}

		// Check if there are any validation errors before submitting
		const hasErrors = Object.values(formErrors).some(
			(error) => error !== false
		);
		console.log(hasErrors);
		if (!hasErrors) {
			// Perform form submission
			//console.log('Form submitted:', formData);
			// if (formData.password === formData.repeatPassword) {
			//   setIsPassMatch("Matched");
			// } else {
			//   setIsPassMatch("Not Matched");
			//   return;
			// }
			//   navigate("/auth/passwordchange");
			let requestModel = {
				Id: formData.id,
				Password: formData.password,
			};
			const result = await SetNewPassword(requestModel);
			try {
				if (isError) {
					throw new Error("Api failed");
				}
				toast.success("Your password is reset successfully!", {
					position: toast.POSITION.TOP_RIGHT,
				});
				// if (response.status === 401) {
				//   navigate("/auth/linkexpire");
				// }
				navigate("/auth/passwordchange");
			} catch (error) {
				// Handle errors (e.g., show an error message)
				console.error("Error during signup:", error);
			}
			setResponse("Wrong username and Password (e.g inCase of login)");
		} else {
			//console.log('Form contains errors. Please correct them before submitting.');
		}
	};
	return (
		<div className={styles.container}>
			<div className={styles.innerContainer}>
				<div className={styles.heading}>
					<div className={styles.text}>Create new password</div>
				</div>
				<form onSubmit={handleSubmit}>
					<div className={styles.input}>
						<Input
							label="Password"
							type="password"
							inputName="password"
							placeholder="Enter Password"
							value={formData.password}
							required
							minLength={12}
							showPasAcceptanceCrieteria
							IsPasswordMatch={isPassMatch}
							onChange={handleChange}
							apiError={response}
						/>
					</div>
					<div className={styles.input} style={{ position: "relative" }}>
						<Input
							label="Repeat Password"
							type="password"
							inputName="repeatPassword"
							placeholder="Enter Repeat Password"
							value={formData.repeatPassword}
							required
							minLength={12}
							IsPasswordMatch={isPassMatch}
							onChange={handleChange}
							apiError={response}
						/>
						<PasswordMatchError isPassMatch={isPassMatch} />
					</div>
					{/* <p>
						{isPassMatch === "Not Matched" && <p>Password is not matched</p>}
					</p> */}
					<div className={styles.button}>
						{isButtonDisabled ? (
							<Button
								title="Confirm"
								type="submit"
								handler={() => { }}
								width={"328px"}
								radius={isMobileScreen ? "10px" : "8px"}
								isDisabled={isButtonDisabled}
								color="#BDBDBD"
								bgColor="#E0E0E0"
							/>
						) : (
							<Button
								title="Confirm"
								handler={() => { }}
								type="submit"
								width={"328px"}
								radius={isMobileScreen ? "10px" : "8px"}
							/>
						)}
					</div>
				</form>
				<ToastContainer />
			</div>
		</div>
	);
};

export default SetNewPassword;
