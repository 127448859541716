import Box from "@ui-kit/components/box/box.component";
import React, { useState, useEffect } from "react";
import "./Successfully_Confirmed.scss";
import useMediaQuery from "@ui-kit/hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { Check } from "@ui-kit/components/icon/icon.component";
import Button from "@ui-kit/components/button/button.component";
import { useActivateUserAccountMutation } from "@/redux/api";
const Successfully_Confirmed = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  //const email = "shiwani.dembla@cinqd.com"
  const [activateUserAccount, { data, error, isLoading }] =
    useActivateUserAccountMutation();
  console.log("dummy data is:", data, error, isLoading);
  useEffect(() => {
    //Getting the Current URL

    const currentUrl = window.location.href;

    //Using Regex to verify the email

    const emailRegex = /email=([^&]+)/;
    const match = currentUrl.match(emailRegex);
    if (match) {
      const urlEmail = decodeURIComponent(match[1]);
      console.log(urlEmail);
      activateUserAccount(urlEmail);
      //setEmail(urlEmail);
      //Setting the email field to the email we got
    }
  }, []);

  const handleButtonClick = async () => {
    navigate("/login");
  };

  return (
    <div>
      {isMobile ? (
        <div className="mobile-container-Confirmation">
          {/* <p> mobile view</p> */}
          <div className="icon" style={{ background: "transparent" }}>
            <Check width={160} height={160} />
          </div>
          <p className="mobile-text">Your account has successfully confirmed</p>
          <Button
            title="Login"
            width="328px"
            height="36px"
            padding="12px, 16px, 12px, 16px"
            handler={handleButtonClick}
            color="white"
            bgColor="#228DFF"
            radius="8px"
          />
        </div>
      ) : (
        <div className="container">
          <Box width={"466px"} height={"350px"}>
            <Check width={200} height={200} />
            <p className="text">Your account has successfully confirmed</p>
            <Button
              title="Login"
              width="420px"
              // padding="10px 20px 10px 10px"
              handler={handleButtonClick}
              color="white"
              bgColor="#228DFF"
              radius="8px"
              // You can customize other props here if needed
            />
          </Box>
        </div>
      )}
    </div>
  );
};

export default Successfully_Confirmed;
