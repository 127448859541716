import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.scss";
import { BrowserRouter } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import { persistor, store } from "./redux/store";
import "bootstrap/dist/css/bootstrap.min.css";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const loginRequest = {
  auth: {
    clientId: "27aa1721-bb9e-4d8d-8d1f-fdf2b78353cb",
    authority:
      "https://login.microsoftonline.com/07eae7d1-de41-410c-aa48-12f192103ace",
  },
  scopes: ["openid", "profile", "user.read"],
};
const instance = new PublicClientApplication(loginRequest);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <MsalProvider instance={instance}>
        <GoogleOAuthProvider clientId="398059348647-fpfvsac3l39n8se5soqgnsd0a47el6km.apps.googleusercontent.com">
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </GoogleOAuthProvider>
      </MsalProvider>
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
