import React from "react";
import './Icon_with_text.styles.scss';
import { HeaderThree } from "../typography/header-three.component";


interface TextProps {   
    text: string;
    fontSize?: string;
    email?:string;
    width: string;
    height: string;
    lineHeight?: string | number;
    fontWeight?: string | number;
}


export const Icon_With_Text :React.FC<TextProps> =({text="", fontSize="14px", fontWeight="600", 
width, height,email,
lineHeight="24px"}) =>{
    // Logic to get the first letter of two words in capital
 
    const words = text.split(" ");
    const firstLetter1 = words[0].charAt(0);
    const firstLetter2 = words[1] ? words[1].charAt(0) : ''; // Handle cases where there might be only one word
    const firstLetters = `${firstLetter1}${firstLetter2}`.toUpperCase();

    return(
    
    <div className="content">
        {/* Icon */}
    <div className="">
    <p className="icon" style={{width:width, height: height}}>{firstLetters}</p>
    </div>
     {/* Text */}
    <div className="text">
    <HeaderThree title={text} fontSize={fontSize} fontWeight={fontWeight} lineHeight={lineHeight} email={email}/>
    </div>
    </div>
)
}