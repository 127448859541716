import React, { ReactNode } from "react";
import './box.styles.css'

interface boxProps {
    //for primitve value: use string, not String
    height?: string;
    width?: string;
    alignItems?:string;
    padding?: {
        default: string;
        small?: string;
        medium?: string;
        large?: string;
    };
    children?: ReactNode;
}


const Box: React.FC<boxProps> = ({ children, height, width, padding, alignItems }) => {
    const boxStyle = {
        height: height ?? '60%',
        width: width ?? '100%',
        '--padding-default': padding?.default || '40px 25px',
        '--padding-small': padding?.small || '30px 10px',
        '--padding-medium': padding?.medium || '40px 20px',
        '--padding-large': padding?.large || '50px 30px',
        'align-items': alignItems ?? 'center',
    };
    return (
        <div className="box-div" >
            <div className="box-inside" style={boxStyle}>
                {children}
                
                {/* content */}
            </div>

        </div>
    )

}


export default Box;