// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link_link__m0gmz {
  color: #228dff;
  font-family: "Roboto" !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  word-wrap: break-word;
  cursor: pointer;
  /* mobile screens */
}
@media only screen and (max-width: 600px) {
  .link_link__m0gmz {
    text-decoration: none;
    font-size: 12px;
    line-height: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/ui-kit/src/components/link/link.module.scss"],"names":[],"mappings":"AAEA;EACC,cAAA;EAEA,gCAAA;EACA,2BAAA;EACA,4BAAA;EACA,qBAAA;EAEA,eAAA;EACA,mBAAA;AAHD;AAIC;EAVD;IAWE,qBAAA;IACA,eAAA;IACA,iBAAA;EADA;AACF","sourcesContent":["@import \"../styles/variables.scss\";\n\n.link {\n\tcolor: #228dff;\n\t//font-size: 12px !important;\n\tfont-family: \"Roboto\" !important;\n\tfont-weight: 400 !important;\n\tline-height: 16px !important;\n\tword-wrap: break-word;\n\t// text-decoration: underline !important;\n\tcursor: pointer;\n\t/* mobile screens */\n\t@media only screen and (max-width: $breakpoint-mobile) {\n\t\ttext-decoration: none;\n\t\tfont-size: 12px;\n\t\tline-height: 16px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `link_link__m0gmz`
};
export default ___CSS_LOADER_EXPORT___;
