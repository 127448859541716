// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input::selection {
  background-color: #228dff;
  color: white;
}

.header24bold {
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.1000000015px;
  text-align: center;
  color: #333333;
}

.body14regular {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1000000015px;
  text-align: center;
  color: #333333;
}

.imgSize {
  width: 24px;
  height: 24px;
  gap: 0px;
  opacity: 0px;
  border-radius: 50%;
}

.body10regular {
  font-family: Roboto;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
}

.color228dff {
  color: #228dff;
}

.pointer {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,SAAA;AACF;;AAEA;;;;;;;;;;EAUE,yBAAA;EACA,YAAA;AACF;;AACA;EAEE,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,8BAAA;EACA,kBAAA;EACA,cAAA;AACF;;AACA;EAEE,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,8BAAA;EACA,kBAAA;EACA,cAAA;AACF;;AACA;EACE,WAAA;EACA,YAAA;EACA,QAAA;EACA,YAAA;EACA,kBAAA;AAEF;;AAAA;EAEA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AAEA;;AAAA;EACC,cAAA;AAGD;;AADA;EACC,eAAA;AAID","sourcesContent":["body {\n  margin: 0;\n}\n\ninput:-webkit-autofill,\ninput:-webkit-autofill:hover,\ninput:-webkit-autofill:focus,\ntextarea:-webkit-autofill,\ntextarea:-webkit-autofill:hover,\ntextarea:-webkit-autofill:focus,\nselect:-webkit-autofill,\nselect:-webkit-autofill:hover,\nselect:-webkit-autofill:focus,\ninput::selection {\n  background-color: #228dff; // Example style, change as needed\n  color: white;\n}\n.header24bold {\n  //styleName: Header/24 Bold;\n  font-family: Open Sans;\n  font-size: 24px;\n  font-weight: 700;\n  line-height: 32px;\n  letter-spacing: 0.10000000149011612px;\n  text-align: center;\n  color: #333333;\n}\n.body14regular {\n  //styleName: Body/14 Regular;\n  font-family: Roboto;\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 16px;\n  letter-spacing: 0.10000000149011612px;\n  text-align: center;\n  color: #333333;\n}\n.imgSize {\n  width: 24px;\n  height: 24px;\n  gap: 0px;\n  opacity: 0px;\n  border-radius: 50%;\n}\n.body10regular{\n\t//styleName: Body/10 Regular;\nfont-family: Roboto;\nfont-size: 10px;\nfont-weight: 400;\nline-height: 12px;\ntext-align: left;\n}\n.color228dff{\n\tcolor: #228dff;\n}\n.pointer{\n\tcursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
