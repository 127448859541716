// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-up-by-invite-one_container__A7xo- {
  text-align: center;
  margin-top: -30px;
  margin-bottom: -20px;
}

.sign-up-by-invite-one_mobile_content__pZ3tR {
  padding: 18px;
  text-align: left;
}

.sign-up-by-invite-one_text_bold__qrbis {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  color: rgb(51, 51, 51);
  line-height: 16px;
  letter-spacing: 0.1000000015px;
  text-align: center;
}

.sign-up-by-invite-one_text__MOGur {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: rgb(51, 51, 51);
  letter-spacing: 0.1000000015px;
  text-align: center;
}

.sign-up-by-invite-one_icon__KKZUZ {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/screens/sign-up/sign-up-by-invite-one.module.scss"],"names":[],"mappings":"AAAA;EACA,kBAAA;EACA,iBAAA;EACA,oBAAA;AACA;;AAEA;EACI,aAAA;EACA,gBAAA;AACJ;;AAEA;EAEA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,sBAAA;EACA,iBAAA;EACA,8BAAA;EACA,kBAAA;AAAA;;AAIA;EAEA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,8BAAA;EACA,kBAAA;AAFA;;AAKA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AAFJ","sourcesContent":[".container {\ntext-align: center;\nmargin-top: -30px;\nmargin-bottom: -20px;\n}\n\n.mobile_content {\n    padding: 18px;\n    text-align: left;\n  \n}\n.text_bold{\n    //styleName: Body/18 Bold;\nfont-family: Roboto;\nfont-size: 14px;\nfont-weight: 700;\ncolor: rgba(51, 51, 51, 1);\nline-height: 16px;\nletter-spacing: 0.10000000149011612px;\ntext-align: center;\n\n}\n\n.text{\n    //styleName: Body/18 Regular;\nfont-family: Roboto;\nfont-size: 14px;\nfont-weight: 400;\nline-height: 16px;\ncolor: rgba(51, 51, 51, 1);\nletter-spacing: 0.10000000149011612px;\ntext-align: center;\n}\n\n.icon{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 10px;\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `sign-up-by-invite-one_container__A7xo-`,
	"mobile_content": `sign-up-by-invite-one_mobile_content__pZ3tR`,
	"text_bold": `sign-up-by-invite-one_text_bold__qrbis`,
	"text": `sign-up-by-invite-one_text__MOGur`,
	"icon": `sign-up-by-invite-one_icon__KKZUZ`
};
export default ___CSS_LOADER_EXPORT___;
