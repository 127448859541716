import React, { useState } from "react"
import styles from "./business-setup.module.scss"
import BusinessTypes from "@ui-kit/components/card/business-types.component"
import soleprop_icon from "../../../../../assets/soleProp_icon.png"
import limited_icon from "../../../../../assets/limitedCom_icon.png"
import solepropBlue_icon from "../../../../../assets/soleProp_blue_icon.png"
import limitedBlue_icon from "../../../../../assets/limitedCom_blue_icon.png"
import Button from "@ui-kit/components/button/button.component"
import { IonInput, IonItem } from '@ionic/react';
import useMediaQuery from "@ui-kit/hooks/useMediaQuery"

const BusinessSetup: React.FC = () => {
    const isMobile = useMediaQuery('(max-width: 400px)');
    const [businessType,setBusinessType] = useState(null||'')
    const handleSetBusinessType= (type:string)=>{
        setBusinessType(type)
    }
    
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.heading}>
                    <div className={styles.text}>Set up your Business</div>
                </div>
                <div className={styles.chooseBusiness}>Choose your business type</div>
                <div className={styles.iconsContainer}>
                    <div className={styles.iconDiv} onClick={()=>{handleSetBusinessType("sole proprietor")}}>
                    {
                        businessType=="sole proprietor"?
                        <BusinessTypes text="Sole Proprietor" icon={solepropBlue_icon} borderColor="#228DFF" color="#228DFF"/>:
                        <BusinessTypes text="Sole Proprietor" icon={soleprop_icon} borderColor="#BDBDBD" color="#333333"/>
                    }
                    </div>
                    <div className={styles.iconDiv} onClick={()=>{handleSetBusinessType("limited company")}}>
                        {
                            businessType=="limited company"?
                            <BusinessTypes text="Limited Company" icon={limitedBlue_icon} borderColor="#228DFF" color="#228DFF"/>:
                            <BusinessTypes text="Limited Company" icon={limited_icon} borderColor="#BDBDBD" color="#333333"/>
                        }
                    </div>
                </div>
                     
                <div className={styles.input}>
                    {
                        businessType=="limited company" &&
                        <IonItem>
                            <IonInput color={"dark"} labelPlacement="floating" label="Registered business name or Company Registration Number (CRN)"  placeholder="Enter text"></IonInput>
                        </IonItem>
                    }
                </div>
                    
                <div className={styles.button}>
                    {
                    !businessType?<Button title="Continue" type="submit" handler={()=>{}} width={isMobile?"328px":"416px"} radius="8px" isDisabled color="#BDBDBD" bgColor="#E0E0E0"/>:
                    <Button title="Continue" handler={()=>{}} type="submit"  width={isMobile?"328px":"416px"} radius="8px"/>
                    }
                </div>
            </div>
        </div>
    )
}

export default BusinessSetup;