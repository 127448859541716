import React, { useState, useEffect } from "react";
import Box from "@ui-kit/components/box/box.component";
import Input from "@ui-kit/components/input/input.component";
import "./signin-with-invite.scss";
import Link from "@ui-kit/components/link/link.component";
import Button from "@ui-kit/components/button/button.component";
import {
  GoogleLoginButton,
  MicrosoftLoginButton,
} from "react-social-login-buttons";
import useMediaQuery from "@ui-kit/hooks/useMediaQuery";

const SigninWithInvite = () => {
  const isMobile = useMediaQuery("(max-width: 360px) and (max-height: 1024px)");
  const [isPassMatch, setIsPassMatch] = useState("Matched");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    repeatPassword: "",
    code: "",
  });
  const [formErrors, setFormErrors] = useState({
    username: false,
    email: false,
    password: false,
    repeatPassword: false,
    code: false,
  });
  const [response, setResponse] = useState("");

  useEffect(() => {
    const isEmailValid = formErrors.email === false;
    const isPasswordValid = formErrors.password === false;
    const isEmailNotEmpty = formData.email.trim() !== "";
    const isPasswordNotEmpty = formData.password.trim() !== "";

    setIsButtonDisabled(
      !(
        isEmailValid &&
        isPasswordValid &&
        isEmailNotEmpty &&
        isPasswordNotEmpty
      )
    );
  }, [formData, formErrors]);

  const handleChange = (
    name: string,
    value: string,
    error: string | boolean
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    // Check if there are any validation errors before submitting
    const hasErrors = Object.values(formErrors).some(
      (error) => error !== false
    );

    if (!hasErrors) {
      // Perform form submission
      console.log("Form submitted:", formData);
      if (formData.password === formData.repeatPassword) {
        setIsPassMatch("Matched");
      } else {
        setIsPassMatch("Not Matched");
        return;
      }
      console.log("Call API here");
      setResponse("Wrong username and Password (e.g in case of login)");
    } else {
      console.log(
        "Form contains errors. Please correct them before submitting."
      );
    }
  };

  return (
    <div className="container">
      {isMobile ? (
        <div className="mobile-container">
          <header className="header">Login</header>
          <form onSubmit={handleSubmit}>
            <div className="input">
              <Input
                label="Email"
                type="email"
                inputName="email"
                placeholder="Enter Email"
                value={formData.email}
                required
                onChange={handleChange}
                apiError={response}
              />
              <Input
                label="Password"
                type="password"
                inputName="password"
                placeholder="Enter Password"
                value={formData.password}
                required
                minLength={12}
                showPasAcceptanceCrieteria
                IsPasswordMatch={isPassMatch}
                onChange={handleChange}
                apiError={response}
              />
            </div>

            <div className="accountInfo">
              {/* <Link
              onClick={()=>{}}
             color="#333333">
              Forgotten password?
            </Link> */}
            </div>
            <div className="button">
              {isButtonDisabled ? (
                <Button
                  title="Login"
                  type="submit"
                  handler={() => {}}
                  width="328px"
                  isDisabled
                  color="#BDBDBD"
                  bgColor="#E0E0E0"
                />
              ) : (
                <Button
                  title="Login"
                  handler={() => {}}
                  isDisabled={false}
                  type="submit"
                  width="328px"
                />
              )}
            </div>
          </form>
          <div className="orDiv">
            <div className="divider"></div>
            <div className="orText">Or</div>
            <div className="divider"></div>
          </div>
          <div className="socialButtons">
            <MicrosoftLoginButton
              onClick={() => alert("Microsoft Login")}
              activeStyle={{ background: "#D3D3D3" }}
              size="41px"
              style={{
                width: "328px",
                fontSize: "14px",
                color: "#333333",
                // border: '1px solid #BDBDBD', // Corrected border declaration
                backgroundColor: "#FFFFFF",
                background: "white",
                fontWeight: 400,
                // color: "black",
                display: "flex", // Add flex display
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
              }}
            >
              Continue with Microsoft
            </MicrosoftLoginButton>
            <GoogleLoginButton
              onClick={() => alert("Google Login")}
              activeStyle={{ background: "##BDBDBD" }}
              size="41px"
              style={{
                width: "328px",
                color: "#333333",
                fontSize: "14px",
                display: "flex", // Add flex display
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
              }}
            >
              Continue with Google
            </GoogleLoginButton>
          </div>
        </div>
      ) : (
        <div className="container">
          <Box width="430px" height="580px" padding={{ default: "20px 30px" }}>
            <div className="div-box">
              <p className="header">
                You have been invited
                <p className="text"> Login to continue</p>
              </p>
              <form onSubmit={handleSubmit}>
                <Input
                  label="Email"
                  type="email"
                  inputName="email"
                  placeholder="Enter Email"
                  value={formData.email}
                  required
                  onChange={handleChange}
                  apiError={response}
                />
                <Input
                  label="Password"
                  type="password"
                  inputName="password"
                  placeholder="Enter Password"
                  value={formData.password}
                  required
                  minLength={12}
                  showPasAcceptanceCrieteria
                  IsPasswordMatch={isPassMatch}
                  onChange={handleChange}
                  apiError={response}
                />
                <div className="accountInfo">
                  {/* <Link
               onClick={()=>{}}
             color="#333333">
              Forgotten password?
            </Link> */}
                </div>
                <div className="button">
                  {isButtonDisabled ? (
                    <Button
                      title="Login"
                      type="submit"
                      handler={() => {}}
                      width="454px"
                      isDisabled
                      color="#BDBDBD"
                      bgColor="#E0E0E0"
                    />
                  ) : (
                    <Button
                      title="Login"
                      handler={() => {}}
                      isDisabled={false}
                      type="submit"
                      width="454px"
                    />
                  )}
                </div>
              </form>
              <div className="orDiv">
                <div className="divider"></div>
                <div className="orText">Or</div>
                <div className="divider"></div>
              </div>
              <div className="socialButtons">
                <MicrosoftLoginButton
                  onClick={() => alert("Microsoft Login")}
                  activeStyle={{ background: "#D3D3D3" }}
                  size="41px"
                  style={{
                    width: "454px",
                    fontSize: "14px",
                    backgroundColor: "white",
                    background: "white",
                    color: "#333333",
                    display: "flex", // Add flex display
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                  }}
                >
                  Continue with Microsoft
                </MicrosoftLoginButton>
                <GoogleLoginButton
                  onClick={() => alert("Google Login")}
                  activeStyle={{ background: "#D3D3D3" }}
                  size="41px"
                  style={{
                    width: "454px",
                    fontSize: "14px",
                    display: "flex",
                    color: "#333333", // Add flex display
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                  }}
                >
                  Continue with Google
                </GoogleLoginButton>
              </div>
            </div>
          </Box>
        </div>
      )}
    </div>
  );
};

export default SigninWithInvite;
