// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.business-types_container__oBz74 {
  width: 176px;
  height: 168px;
  margin: 5px;
  border-radius: 8px;
}
@media only screen and (max-width: 360px) {
  .business-types_container__oBz74 {
    width: 148px;
    height: 128px;
  }
}

.business-types_card__IeqKn {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: rgb(189, 189, 189) !important;
}

.business-types_icon__2HSL9 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 35px;
}
@media only screen and (max-width: 360px) {
  .business-types_icon__2HSL9 {
    margin-top: 18px;
  }
}

.business-types_text__Rtqss {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.1000000015px;
  text-align: center;
}
@media only screen and (max-width: 360px) {
  .business-types_text__Rtqss {
    font-size: 14px;
  }
}`, "",{"version":3,"sources":["webpack://./src/ui-kit/src/components/card/business-types.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,WAAA;EACA,kBAAA;AACJ;AAAI;EALJ;IAMQ,YAAA;IACA,aAAA;EAGN;AACF;;AAAA;EACI,4BAAA;EACA,8BAAA;EACA,2CAAA;AAGJ;;AAAA;EACI,wBAAA;EACA,kCAAA;EACA,8BAAA;EACA,gBAAA;AAGJ;AAFI;EALJ;IAMQ,gBAAA;EAKN;AACF;;AAFA;EACA,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EAIA,8BAAA;EACA,kBAAA;AAEA;AANA;EALA;IAMI,eAAA;EASF;AACF","sourcesContent":[".container{\n    width: 176px;\n    height: 168px;\n    margin: 5px;\n    border-radius: 8px;\n    @media only screen and (max-width: '360px') {\n        width: 148px;\n        height: 128px;\n    }\n}\n\n.card{\n    border-width: 1px !important;\n    border-style: solid !important;\n    border-color: rgba(189, 189, 189, 1) !important;   \n}\n\n.icon {\n    display: flex !important;\n    justify-content: center !important;\n    align-items: center !important;\n    margin-top: 35px;\n    @media only screen and (max-width: '360px') {\n        margin-top: 18px;  \n    }\n}\n\n.text{\nfont-family: Open Sans;\nfont-size: 18px;\nfont-weight: 600;\nline-height: 24px;\n@media only screen and (max-width: '360px') {\n    font-size: 14px;\n}\nletter-spacing: 0.10000000149011612px;\ntext-align: center;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `business-types_container__oBz74`,
	"card": `business-types_card__IeqKn`,
	"icon": `business-types_icon__2HSL9`,
	"text": `business-types_text__Rtqss`
};
export default ___CSS_LOADER_EXPORT___;
