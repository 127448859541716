// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  gap: 8px;
  display: flex;
  text-align: center;
}

.icon_div {
  width: Fixed 60px;
  height: Fixed 60px;
}

.icon {
  background: rgb(45, 212, 191);
  border-radius: 50%;
  color: rgba(247, 251, 253, 0.9);
  font-size: 14px;
  font-weight: 400;
  line-height: 12px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.text {
  margin-top: 6px;
}`, "",{"version":3,"sources":["webpack://./src/ui-kit/src/components/Icon%20With%20Text/Icon_with_text.styles.scss","webpack://./src/ui-kit/src/components/Icon With Text/Icon_with_text.styles.scss"],"names":[],"mappings":"AAEA;EAGE,QAAA;EACF,aAAA;EACA,kBAAA;ACHA;;ADMA;EACM,iBAAA;EACA,kBAAA;ACHN;;ADMA;EACA,6BAAA;EACC,kBAAA;EACD,+BAAA;EAEA,eAAA;EACA,gBAAA;EACC,iBAAA;EACD,kBAAA;EACC,uBAAA;EACA,mBAAA;EACA,aAAA;ACJD;;ADMA;EACI,eAAA;ACHJ","sourcesContent":["@import \"../styles/variables.scss\";\n\n.content {\n//     width: Fixed (218px);\n//  height: Hug (40px);\n  gap: 8px;\ndisplay: flex;\ntext-align: center;\n}\n\n.icon_div{\n      width: Fixed (60px);\n      height: Fixed (60px);\n   \n}\n.icon{\nbackground: rgba(45, 212, 191, 1);\n border-radius: 50%;\ncolor:rgba(247, 251, 253, 0.9);\n//font-family: Inter;\nfont-size: 14px;\nfont-weight: 400;\n line-height: 12px;\ntext-align: center;\n justify-content: center;\n align-items: center;\n display: flex;\n}\n.text{\n    margin-top: 6px;\n}\n\n",".content {\n  gap: 8px;\n  display: flex;\n  text-align: center;\n}\n\n.icon_div {\n  width: Fixed 60px;\n  height: Fixed 60px;\n}\n\n.icon {\n  background: rgb(45, 212, 191);\n  border-radius: 50%;\n  color: rgba(247, 251, 253, 0.9);\n  font-size: 14px;\n  font-weight: 400;\n  line-height: 12px;\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n  display: flex;\n}\n\n.text {\n  margin-top: 6px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
