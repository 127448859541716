type Props = {
	isPassMatch: string;
};
const PasswordMatchError = ({ isPassMatch }: Props) => {
	return (
		<>
			{isPassMatch === "Not Matched" && (
				<small
					style={{
						position: "absolute",
						color: "red",
						fontSize: "10px",
						top: "30px",
						padding: "2px 0",
					}}
				>
					Please match password and repeat password
				</small>
			)}
		</>
	);
};
export default PasswordMatchError;
