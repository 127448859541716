import React from "react";

interface ButtonProps {
	title: string;
	handler: () => void;
	color?: string;
	width?: string;
	height?: string;
	type?: "button" | "submit" | "reset";
	bgColor?: string;
	radius?: string;
	isDisabled?: boolean;
	padding?: string;
	fontFamily?: string;
	fontSize?: string;
	fontStyle?: string;
	fontWeight?: string;
	lineHeight?: string;
	letterSpacing?: string;
}

const Button: React.FC<ButtonProps> = ({
	title,
	handler,
	color = "var(--gray-white, #FFF)",
	width = "100px",
	height = "50px",
	type = "button",
	bgColor = "var(--blue-05, #228DFF)",
	radius = "4px",
	isDisabled = false,
	padding = "12px 16px 12px 16px",
	fontFamily = "Roboto",
	fontSize = "16px",
	fontStyle = "normal",
	fontWeight = "400",
	lineHeight = "24px",
	letterSpacing = " 0.1px",
}) => {
	const buttonStyle: React.CSSProperties = {
		color,
		width,
		height,
		background: bgColor,
		borderRadius: radius,
		cursor: isDisabled ? "not-allowed" : "pointer",
		padding: padding,
		fontFamily: fontFamily,
		fontSize: fontSize,
		fontStyle: fontStyle,
		fontWeight: fontWeight,
		lineHeight: lineHeight,
		letterSpacing: letterSpacing,
		border: "none",
	};

	return (
		<button
			type={type}
			onClick={handler}
			style={buttonStyle}
			disabled={isDisabled}
		>
			{title}
		</button>
	);
};

export default Button;
