// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-in-by-Invite-one_container__D9tQ\\+ {
  text-align: center;
  margin-top: -30px;
  margin-bottom: -30px;
}

.sign-in-by-Invite-one_mobile_content__qCeYq {
  padding: 18px;
  text-align: left;
}

.sign-in-by-Invite-one_text_bold__zLT4Y {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  color: rgb(51, 51, 51);
  line-height: 16px;
  letter-spacing: 0.1000000015px;
  text-align: center;
}
@media only screen and (max-width: 360px) {
  .sign-in-by-Invite-one_text_bold__zLT4Y {
    font-size: 14px;
    line-height: 16px;
  }
}

.sign-in-by-Invite-one_text__fL7Uz {
  font-family: Roboto;
  font-size: 14px;
  color: rgb(51, 51, 51);
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1000000015px;
  text-align: center;
}
@media only screen and (max-width: 360px) {
  .sign-in-by-Invite-one_text__fL7Uz {
    font-size: 14px;
    line-height: 16px;
  }
}

.sign-in-by-Invite-one_icon__ECIW8 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/auth/screens/sign-in/sign-in-by-Invite-one.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,iBAAA;EACA,oBAAA;AACJ;;AAEI;EACI,aAAA;EACA,gBAAA;AACR;;AAEI;EAEA,mBAAA;EACA,eAAA;EACA,gBAAA;EACA,sBAAA;EACA,iBAAA;EACA,8BAAA;EACA,kBAAA;AAAJ;AAEI;EAVA;IAWI,eAAA;IACA,iBAAA;EACN;AACF;;AAEI;EAEA,mBAAA;EACA,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,iBAAA;EACA,8BAAA;EACA,kBAAA;AAAJ;AACI;EATA;IAUI,eAAA;IACA,iBAAA;EAEN;AACF;;AAEI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AACR","sourcesContent":[".container {\n    text-align: center;\n    margin-top: -30px;\n    margin-bottom: -30px;\n    }\n    \n    .mobile_content {\n        padding: 18px;\n        text-align: left;\n   \n     }\n    .text_bold{\n    //styleName: Body/18 Bold;\n    font-family: Roboto;\n    font-size: 14px;\n    font-weight: 700;\n    color: rgba(51, 51, 51, 1);\n    line-height: 16px;\n    letter-spacing: 0.10000000149011612px;\n    text-align: center; \n    \n    @media only screen and (max-width: 360px) {\n        font-size: 14px;  \n        line-height: 16px;\n    }\n    }\n    \n    .text{\n        //styleName: Body/18 Regular;\n    font-family: Roboto;\n    font-size: 14px;\n    color: rgba(51, 51, 51, 1);\n    font-weight: 400;\n    line-height: 16px;\n    letter-spacing: 0.10000000149011612px;\n    text-align: center;\n    @media only screen and (max-width: '360px') {\n        font-size: 14px;  \n        line-height: 16px;\n    }\n    }\n\n\n    .icon{\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        margin-bottom: 10px;\n        margin-top: 10px;\n    }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `sign-in-by-Invite-one_container__D9tQ+`,
	"mobile_content": `sign-in-by-Invite-one_mobile_content__qCeYq`,
	"text_bold": `sign-in-by-Invite-one_text_bold__zLT4Y`,
	"text": `sign-in-by-Invite-one_text__fL7Uz`,
	"icon": `sign-in-by-Invite-one_icon__ECIW8`
};
export default ___CSS_LOADER_EXPORT___;
